<template>
  <div class="authBefore-container">
    <div class="authBefore-image">
      <v-iconFontSymbol
        :icon="'iconlogo_blue'"
        :style="{ 'font-size': '2rem', height: '110px' }"
      />
    </div>
    <div class="button-container">
      <div @click="toAuth" class="container-other">
        <v-iconFont
          :className="'iconwechat_white'"
          :style="{ 'font-size': '0.18rem', color: '#ffffff' }"
        />
        <span>微信登陆</span>
      </div>
    </div>
  </div>
</template>

<script>
import IconFont from "@/components/IconFont.vue";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import { appid } from "../tools/config";

export default {
  name: "AuthBefore",
  components: {
    "v-iconFont": IconFont,
    "v-iconFontSymbol": IconFontSymbol
  },
  setup() {
    const toAuth = () => {
      let redirectUrl = encodeURIComponent(window.location.origin);
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
    };
    return {
      toAuth
    };
  }
};
</script>
