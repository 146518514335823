<template>
  <div class="icon-container">
    <i :class="'iconfont ' + this.className" :style="this.style"></i>
  </div>
</template>

<script>
import "../sheet/iconFont/iconfont.css";
import "../sheet/iconFont/iconfont.js";

export default {
  name: "IconFont",
  props: ["className", "style"]
};
</script>
